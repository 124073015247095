import React from 'react'
import './Style.css'

function Terms() {
  return (
    <>
    {/* <div className="terms-container">
        <div className="terms-main-box">
            <Link to="/" title="Naans by Shubhi and The Atlantic Pub - An Indo-Canadian Fusion Food Services">
                <img src={logonew} alt="" title="Naans by Shubhi and The Atlantic Pub - An Indo-Canadian Fusion Food Services" />
            </Link>
        </div>
    </div> */}

    <div className="container terms px-5">
        <div className="row text-center">
            <div className="col align-self-center">
                <div className="py-2 ">
                    <h2>Terms and Conditions</h2>
                    <h4>for Naans by Shubi's</h4>
                    <h3> Reviews Campaign</h3>
                </div>
            </div>
        </div>
        <div className="row text-start">
            <div className="col align-self-center">
                <div className="py-3">
                <ol>
                <li>Eligibility</li>
                    <p>This campaign is open to all customers of Naans by Shubi. Employees, affiliates, and immediate family members of employees are not eligible to participate.</p>
                <li>Campaign Duration
                    <ol type='a'>
                        <li>The campaign will run from 15th Sep 2023 to 15th Oct 2023. Reviews made outside this timeframe will not qualify for the discount.</li>
                        <li>Management reserves the right to extend, modify, or terminate the campaign at any time without prior notice.</li>
                    </ol>
                </li>
                <li>Participation
                    <ol type="a">
                        <li>Leave a review on Google Review, Facebook, or TripAdvisor for Naans by Shubi.</li>
                        <li>Reviews should be maintained for a minimum duration of 30 days from the date of posting</li>
                        <li>Customers can avail the discount immediately after posting the reviews</li>
                        <li>Share Naans by Shubi’s social media profile on their personal social media accounts.</li>
                    </ol>
                </li>
                <li>Proof of Review & Share</li>
                    <p>To avail the discount, customers must present valid screenshots of their review and social media share at the time of order placement. The final discretion in the acceptance of proof lies with the management of Naans by Shubi.</p>
                <li>Discount Terms
                    <ol type="a">
                        <li>The discount is applicable to the bill amount before taxes.</li>
                        <li>The discount cannot be combined with any other promotional offers or discounts.</li>
                        <li>The discount is not redeemable for cash or any other alternatives and is non-transferable.</li>
                    </ol>
                </li>
                <li>Right to Modify or Cancel</li>
                    <p>Naans by Shubi reserves the right to modify, suspend, or cancel the campaign without any prior notice in the event of unforeseen circumstances beyond our control.</p>
                <li>Limitation of Liability</li>
                    <p>Participants agree to release and hold harmless Naans by Shubi, its affiliates, and employees from any and all liability or any injuries, loss, or damage of any kind arising from or in connection with this campaign.</p>
                <li>Data Use</li>
                    <p>By participating, customers consent to Naans by Shubi's use of their reviews, social media usernames, and shared content for promotional purposes.</p>
                <li>Governing Law</li>
                    <p>These terms and conditions are governed by and construed in accordance with the laws of Ontario, Canada.</p>
                <li>Acceptance of Terms</li>
                    <p>Participation in the campaign indicates an unconditional agreement to and acceptance of these Terms & Conditions. It is the participant's responsibility to review them periodically for changes.</p>
                <li>Discount Limitation</li>
                    <p>Each customer is eligible for the discount only once, regardless of the number of reviews or shares. Multiple reviews or shares will not result in additional discounts.</p>
                <li>Final Decision</li>
                    <p>The management of Naans by Shubi holds the final decision in any dispute arising from this campaign.</p>
            </ol>
            {/* <ol>
                <li>Eligibility
                    <p>This campaign is open to all customers of Naans by Shubi. Employees and immediate family members of Naans by Shubi are excluded from participation.</p>
                </li>
                <li>Participation
                    <ol type="a">
                        <li>Leave a review on Google Review, Facebook, or TripAdvisor for Naans by Shubi.</li>
                        <li>Share Naans by Shubi’s social media profile on their personal social media accounts.</li>
                    </ol>
                </li>
                <li>Proof of Review & Share
                    <p>To avail the discount, customers must present valid screenshots of their review and social media share at the time of order placement.</p>
                </li>
                <li>Discount Application
                    <p>The 10% discount will be applied to the total bill before taxes. It cannot be combined with other offers or promotions.</p>
                </li>
                <li>Duration & Termination
                    <p>Management reserves the right to extend, modify, or terminate the campaign at any time without prior notice.</p>
                </li>
                <li>Discount Limitation
                    <p>Each customer is eligible for the discount only once, regardless of the number of reviews or shares. Multiple reviews or shares will not result in additional discounts.</p>
                </li>
                <li>Final Decision
                    <p>The management of Naans by Shubi holds the final decision in any dispute arising from this campaign.</p>
                </li>
            </ol> */}
                </div>
            </div>
        </div>
    </div>

    </>
  )
}

export default Terms