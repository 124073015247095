import React from 'react'
import bg4 from '../../../assets/images/background/bg-4.png'

import samosa from '../../../assets/images/resource/samosa.webp'
import gobhiGarlic from '../../../assets/images/resource/gobhi-garlic.webp'
import onionpakora from '../../../assets/images/resource/onion-pakora.webp'
import vegpakora from '../../../assets/images/resource/vegetable-pakora.webp'
import chickenpakora from '../../../assets/images/resource/chicken-pakora.webp'
import paneerpakora from '../../../assets/images/resource/paneer-pakora.webp'


// This file is used

function Appetizers() {
    return (
        <>
            <section className="menu-two">

                <div className="right-bg"><img src={bg4} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>STARTER MENU</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Appetizers</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={samosa} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Samosa</h5></div><div className="price"><span>$ 6.95</span></div></div>
                                        <div className="text desc">A triangular pastry filled with a savoury mixture of spiced potatoes, peas, and a blend of Indian spices, then deep-fried until crispy and golden brown.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={gobhiGarlic} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Gobi Garlic</h5></div><div className="price"><span>$ 9.95</span></div></div>
                                        <div className="text desc">Cauliflower florets coated with a batter of chickpea flour and Indian spices, then deep fried to get a crispy and mouth watering texture. Tossed in aromatic garlic sauce this is a must try as a snack or a side dish.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={onionpakora} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Onion Bhaji</h5></div><div className="price"><span>$ 8.95</span></div></div>
                                        <div className="text desc">Coated with chickpea batter and spiced up with Indian spices, these fried onion fritters are the perfect appetizer or snack to satisfy your cravings for something savoury and satisfying.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={vegpakora} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Veg Pakoda</h5></div><div className="price"><span>$ 8.95</span></div></div>
                                        <div className="text desc">A blend of different vegetables coated with chickpea batter and spiced up with Indian spices, then deep-fried to get a crispy texture. A perfect appetizer or snack to satisfy your cravings for something savoury and satisfying.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={chickenpakora} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Chicken Pakora</h5></div><div className="price"><span>$12.95</span></div></div>
                                        <div className="text desc">Made with succulent chicken pieces marinated in a flavorful blend of spices and coated with a crispy batter. Chicken pakoras are a perfect start to any meal or a delicious snack on their own.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={paneerpakora} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Paneer Pakora</h5></div><div className="price"><span>$11.95</span></div></div>
                                        <div className="text desc">Crispy fritters made with Indian cheese and a blend of spices. Served with chutney for a perfect start to your meal.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>

            </section>
        </>
    )
}

export default Appetizers