import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel';
import Terms from './TermsAndConditions';

function Main() {
  useEffect(() => {
    ReactPixel.init('1727601077676996');
    ReactPixel.pageView();
  }, []);

  return (
    <>
      <Helmet>
        <meta name='description' content='Discover authentic Indian cuisine in Alexandria, Canada. Experience vibrant flavors, exceptional service, and find us easily on Google Maps.' />
        <meta name="title" content="About Naans by Shubi / The Atlantic PUb, Alexandia, Canada" />
      </Helmet>
      <Terms/>

      {/* <Contactbanner /> */}
    </>
  );
}

export default Main;
