import React, {useEffect} from 'react'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/menu-banner1.webp'
import TandooriBanner from '../../assets/images/background/banner_tandoori.webp'
import Story from '../Menhome/Story'
import Offer from '../Menhome/Offer'
import Specialdish from '../Menhome/Specialdish'
import Special from '../Menhome/Special'
import { Helmet } from 'react-helmet'
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel';




// this is the file being used

function Aboutus() {
  // facebook pixel 

  useEffect(() => {
    ReactPixel.init('1727601077676996');
    ReactPixel.pageView();
    ReactPixel.trackCustom();
  }, []);
  return (

    <>
    <Helmet>
    <meta
      name="description"
      content="Learn about our restaurant's story, passion for culinary excellence, and commitment to delivering an unforgettable dining experience."
    />
    <meta name="title" content="About Us - Takeout Food in Alexandria, Canada" />
    </Helmet>
      <Bredcrumb
        subtitle="Delivering Freshness"
        title="Our Story"
        Img={Img}
      />
      <Story />
      <Offer />
      <Bredcrumb
        subtitle="Try Fusion Food Today"
        title="Explore our Menu"
        Img={TandooriBanner}
      />
      <Specialdish />
      <Special />
    </>

  )
}

export default Aboutus