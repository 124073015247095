import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Videosection from '../Menhome/Videosection'
import Special from '../Menhome/Special'
import Banner from '../Menhome/Banner'
import Offer from '../Menhome/Offer'
import Story from '../Menhome/Story'
import Specialdish from '../Menhome/Specialdish'
import Menusection from '../Menhome/Menusection'
import Whyus from '../Menhome/Whyus'
import Review from '../Menhome/Reviews'
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel';




// This file is controlling the main landing page

function Main() {
    useEffect(() => {
        ReactPixel.init('1727601077676996');
        ReactPixel.pageView();
      }, []);
    return (

        <>
        <Helmet>
            <meta name='description' content='Authentic Indian restaurant in Alexandria, Ontario, Canada. Order delicious butter Chicken, tandoori chicken and more. Call now or order online for takeaway.' />
            <meta name="title" content="Best Indian Food in Alexandria, Ontario, Canada Order now for Takeaway" />
        </Helmet>

            <div className="menu-backdrop"></div>
            <Banner />
            <Review/>
            <Menusection />
            <Special />
            <Specialdish />
            <Whyus />
            <Offer />
            <Story />
            <Videosection />
        </>

    )
}

export default Main