import React, {useEffect} from 'react'
import Bredcrumb from '../Bredcrumb/Main'
import EnglishBanner from '../../assets/images/background/banner-english.jpg'
import Canadianmeals from '../Allmenus/Thrdmenu/Canadianmeals'
import { Helmet } from 'react-helmet'
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel';



// this is the file being used

function Englishmenuitems() {
  // facebook pixel
  useEffect(() => {
    ReactPixel.init('1727601077676996');
    ReactPixel.pageView();
  }, []);
  return (
    <>
    <Helmet>
    <meta
      name="description"
      content="Experience the best of Canadian cuisine at our restaurant. Tossed Wings, Indulge in hearty dishes like poutine, chicken tenders, Beer Batter Fish, and more."
    />
    <meta name="title" content="Canadian Meals - in Alexandria, Canada" />
    </Helmet>
       <Bredcrumb
        subtitle="English Menu"
        title="English Menu"
        Img={EnglishBanner}
      />
       <Canadianmeals />
    </>

  )
}

export default Englishmenuitems