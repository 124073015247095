import React from 'react'
import bg9 from '../../../assets/images/background/bg-9.png'
import bg100 from '../../../assets/images/background/bg-10.png'
import bg1 from '../../../assets/images/background/bg-1.png'
import pizza1 from '../../../assets/images/resource/pizza1.jpg'
import pizza2 from '../../../assets/images/resource/pizza2.jpg'
import pizza3 from '../../../assets/images/resource/pizza3.jpg'




function Pizza() {
    return (

        <>
            <section className="menu-one">
                <div className="left-bg"><img src={bg100} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage'/>
                        </div>

                        <h2>Pizza</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={pizza1} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Butter Chicken Pizza</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">A delightful fusion of classic butter chicken and pizza, with tender chicken tikka, creamy butter sauce, and a perfect blend of Indian spices, all on a crispy pizza crust.</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box" style={{minHeight:'10px'}}>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Tikka Masala Pizza</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Experience the vibrant flavors of India with our Tikka Masala Pizza. Savory chicken tikka, smothered in rich and aromatic tikka masala sauce, topped with melted cheese, and baked to perfection.</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Jalfrezi Pizza</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Spice up your pizza game with our Jalfrezi Pizza. Enjoy a fiery combination of marinated chicken, bell peppers, onions, and exotic Indian spices, all atop a golden crust for an unforgettable taste sensation.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="menu-one alternate">
                <div className="right-bg"><img src={bg1} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={pizza2} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Chicken Caesar Pizza</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Savor the best of both worlds with our Chicken Caesar Pizza. Tender grilled chicken, crispy romaine lettuce, tangy Caesar dressing, and a sprinkle of Parmesan cheese, all on a thin and crispy pizza crust.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Philly Cheese Steak Pizza</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Experience the taste of Philadelphia with our Philly Cheese Steak Pizza. Thinly sliced steak, sautéed onions, bell peppers, and a generous layer of melted provolone cheese, all on a mouthwatering pizza crust.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Pepperoni and Cheese Pizza</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Classic and timeless, our Pepperoni and Cheese Pizza is a true crowd-pleaser. Generously topped with zesty pepperoni slices, gooey melted cheese, and our signature tomato sauce.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="menu-one">
                <div className="left-bg"><img src={bg9} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={pizza3} alt=""/></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Buffalo Chicken Pizza</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Get your taste buds soaring with our Buffalo Chicken Pizza. Juicy chicken tossed in spicy buffalo sauce, complemented by melted cheese, and topped with a drizzle of cooling ranch dressing. A spicy and savory delight.</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box" style={{minHeight:'10px'}}>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Mediterranean Pizza</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Take your taste buds on a Mediterranean vacation with our Mediterranean Pizza. A delectable combination of sun-dried tomatoes, olives, feta cheese, and aromatic herbs, all on a thin and crispy crust.</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Weekly Chef's Special Pizza</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Indulge in our ever-changing Weekly Chef's Special Pizza, where our culinary team brings you the freshest and most innovative flavors. Ask your server to discover this week's extraordinary pizza creation.</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>


    )
}

export default Pizza