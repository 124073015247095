import React, { useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Appetizers from '../Allmenus/Thrdmenu/Appetizers'
import Soups from '../Allmenus/Thrdmenu/Soups'
import Tandoori from '../Allmenus/Thrdmenu/Tandoori'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/menu-banner1.webp'
import TandooriBanner from '../../assets/images/background/banner_tandoori.webp'
import CurriesBanner from '../../assets/images/background/banner-curries.webp'
import BriyaniBanner from '../../assets/images/background/banner-briyani.jpg'
import EnglishBanner from '../../assets/images/background/banner-english.jpg'
import NaanBanner from '../../assets/images/background/naanbanner.jpg'
import MainCourseVeg from '../Allmenus/Thrdmenu/MainCourseVeg'
import MainCourseNonVeg from '../Allmenus/Thrdmenu/MainCourseNonVeg'
import Rice from '../Allmenus/Thrdmenu/Rice'
import Briyanibig from '../Allmenus/Thrdmenu/Briyanibig'
import Combomeals from '../Allmenus/Thrdmenu/Combomeals'
import Canadianmeals from '../Allmenus/Thrdmenu/Canadianmeals'
import Pizza from '../Allmenus/Thrdmenu/Pizza'
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel';


// this is the file being used

function Main() {
  useEffect(() => {
    ReactPixel.init('1727601077676996');
    ReactPixel.pageView();
  }, []);
  return (

    <>
    <Helmet>
    <meta name="title" content="Naans by Shubi - Takeout Menu" />
    <meta name="description" content="Experience the best of Indian cuisine in Alexandria, Canada. Explore our diverse menu, savor authentic flavors, and satisfy your craving for Indian food." />
    </Helmet>
      <Bredcrumb
        subtitle="Our Menu"
        title="DELICIOUS & AMAZING"
        Img={Img}
      />
      <Appetizers />
      <Soups/>
      <Bredcrumb
        subtitle="Smoke it up !!!"
        title="From the Tandoor"
        Img={TandooriBanner}
      />
      <Tandoori/>
      <Bredcrumb
        subtitle="Curries and More !!!"
        title="Main Course"
        Img={CurriesBanner}
      />
      <MainCourseVeg/>
      <MainCourseNonVeg/>
      <Bredcrumb
        subtitle="Naans, Rice & Pizzas"
        title="Main Course"
        Img={NaanBanner}
      />
      <Rice />
      <Pizza />
      <Bredcrumb
        subtitle="Briyanis and Combos"
        title="Main Course"
        Img={BriyaniBanner}
      />
      <Briyanibig />
      <Combomeals />
      <Bredcrumb
        subtitle="English Menu"
        title="English Menu"
        Img={EnglishBanner}
      />
      <Canadianmeals />


    </>

  )
}

export default Main