import React from 'react'
import bg10 from '../../../assets/images/background/bg-10.png'
import bg18 from '../../../assets/images/background/bg-18-1.png'
import combo1 from '../../../assets/images/resource/combo1.webp'
import combo2 from '../../../assets/images/resource/combo2.webp'


function Combomeals() {
    return (

        <>
            <section className="menu-one">
                <div className="right-bg-2"><img src={bg10} alt="" title="" /></div>
                {/* <div className="left-bg"><img src={bg40} alt="" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Combo Meals</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage'/>
                        </div>

                        <h2>Indian Combo Meals</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={combo1} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Combo 1</h5></div><div className="price"><span>$45.95</span></div></div>
                                        <div className="text desc">Vegetable Samosa, Chana Masala, Vegetable Coconut Curry, Rice, and Naan</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>combo 2</h5></div><div className="price"><span>$45.95</span></div></div>
                                        <div className="text desc">Vegetable Samosa, Butter Chicken, Cauliflower and Potato, Rice, and Naan</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Combo 3</h5></div><div className="price"><span>$56.95</span></div></div>
                                        <div className="text desc">Chicken Pakora, Butter Chicken, Beef/Pork Curry, Rice, and Naan</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="menu-one alternate">

                <div className="right-bg" style={{zIndex:'10'}}><img src={bg18} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={combo2} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Diary Free Combo</h5></div><div className="price"><span>$45.95</span></div></div>
                                        <div className="text desc">Garlic Cauliflower, Mixed Vegetable Vindaloo, Chicken Coconut Curry, Rice and Whole Wheat Rot</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Gluten Free Combo</h5></div><div className="price"><span>$45.95</span></div></div>
                                        <div className="text desc">Vegetable Pakora, Daal Makhani, Chicken Saagwala, Rice, Papadum</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Vegan Combo</h5></div><div className="price"><span>$45.95</span></div></div>
                                        <div className="text desc">Garlic Cauliflower, Daal Turka, Eggplant Bharta, Rice, and Whole Wheat Roti.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
        </>


    )
}

export default Combomeals