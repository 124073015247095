import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';
import menuimgone from '../../assets/images/resource/menu-image-11.jpg'
import menuimgtwo from '../../assets/images/resource/menu-image-12.jpg'
import menuimgthree from '../../assets/images/resource/menu-image-13.jpg'
import menuimgfour from '../../assets/images/resource/menu-image-14.jpg'
import menuimgfive from '../../assets/images/resource/menu-image-20.jpg'
import menuimgsix from '../../assets/images/resource/menu-image-21.jpg'
import menuimgseven from '../../assets/images/resource/menu-image-22.jpg'
import menuimgeight from '../../assets/images/resource/menu-image-23.jpg'


function Special() {
    const options = {
        items: 4,
        rewind: true,
        loop: true,
        margin: 45,
        nav: true,
        smartSpeed: 700,
        autoplay: true,
        autoplayTimeout: 7000,
        navText: ['<span class="icon fa-light fa-angle-left"></span>', '<span class="icon fa-light fa-angle-right"></span>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2,
                margin: 30
            },
            992: {
                items: 3,
                margin: 30
            },
            1200: {
                items: 4
            }
        }
    };

    return (

        <>
            <section className="special-offer">
                <div className="outer-container">

                    <div className="auto-container">

                        <div className="title-box centered">
                            <div className="subtitle"><span> chef's recommendations </span></div>

                            <div className="pattern-image">
                                <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                            </div>

                            <h2> Our Specialties </h2>
                        </div>

                        <OwlCarousel className="dish-gallery-slider owl-carousel owl-theme" options={options}>

                            <div className="offer-block-two">
                                <div className="inner-box">
                                    <div className="image"><img src={menuimgone} alt="" /></div>
                                    <h4>Tandoori Chicken</h4>
                                    <div className="text desc">A classic Indian dish made with marinated chicken grilled in a traditional clay oven. Served with mint chutney.
                                    </div>
                                    <div className="price">$18.95</div>
                                </div>
                            </div>

                            <div className="offer-block-two margin-top">
                                <div className="inner-box">
                                    <div className="image"><img src={menuimgtwo} alt="" /></div>
                                    <h4>Eggplant Bharta</h4>
                                    <div className="text desc">Roasted eggplant mashed and cooked with aromatic spices. The smoky flavour will surely satisfy your palate</div>
                                    <div className="price">$17.95</div>
                                </div>
                            </div>

                            <div className="offer-block-two">
                                <div className="inner-box">
                                    <div className="image"><img src={menuimgthree} alt="" /></div>
                                    <h4>Seekh Kabab</h4>
                                    <div className="text desc">Spicy minced meat skewers grilled to perfection. A popular Indian appetizer.</div>
                                    <div className="price">$18.95</div>
                                </div>
                            </div>

                            <div className="offer-block-two margin-top">
                                <div className="inner-box">
                                    <div className="image"><img src={menuimgfour} alt="" /></div>
                                    <h4>Palak Kofta</h4>
                                    <div className="text desc">Vegetarian meatballs made with spinach and Indian cheese cooked in a rich tomato-based sauce. A must-try for vegetarians.</div>
                                    <div className="price">$17.95</div>
                                </div>
                            </div>


                            <div className="offer-block-two">
                                <div className="inner-box">
                                    <div className="image"><img src={menuimgfive} alt="" /></div>
                                    <h4>Chicken Seekh Kabab</h4>
                                    <div className="text desc">A succulent chicken version of the classic seekh kabab. A perfect start to any meal.</div>
                                    <div className="price">$19.95</div>
                                </div>
                            </div>


                            <div className="offer-block-two margin-top">
                                <div className="inner-box">
                                    <div className="image"><img src={menuimgsix} alt="" /></div>
                                    <h4>Combo 3</h4>
                                    <div className="text desc">A combination of Chicken Pakora, Butter Chicken, Beef/Pork Curry, Rice, and Naan. Complete dinner for two.</div>
                                    <div className="price">$56.95</div>
                                </div>
                            </div>


                            <div className="offer-block-two">
                                <div className="inner-box">
                                    <div className="image"><img src={menuimgseven} alt="" /></div>
                                    <h4>Chicken Biryani</h4>
                                    <div className="text desc">A classic Indian dish made with long grain basmati rice, tender chicken pieces, and aromatic spices resulting in a fragrant and flavorful dish.
                                    </div>
                                    <div className="price">$19.95</div>
                                </div>
                            </div>


                            <div className="offer-block-two margin-top">
                                <div className="inner-box">
                                    <div className="image"><img src={menuimgeight} alt="" /></div>
                                    <h4>Butter Sauce</h4>
                                    <div className="text desc">A creamy and buttery tomato-based sauce. Perfect for dipping naan or as a sauce for meat dishes.</div>
                                    <div className="price">$19.95</div>
                                </div>
                            </div>


                        </OwlCarousel>

                        <div className="lower-link-box text-center">
                            <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                <span className="btn-wrap">
                                    <span className="text-one">view all menu</span>
                                    <span className="text-two">view all menu</span>
                                </span>
                            </Link>
                        </div>

                    </div>

                </div>
            </section>
        </>

    )
}

export default Special