import React from 'react'
import Iframe from 'react-iframe'
import bg25 from '../../assets/images/background/bg-25.png'
import bg6 from '../../assets/images/background/bg-6.png'

// contact banner section

function Contactbanner() {
    return (

        <>

            <div className="contact-map" style={{zIndex:'10'}}>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2805.5414143323587!2d-74.64280412408256!3d45.317688471071875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccea33611a2b271%3A0xbc6988cee2ebb4f9!2sNaans%20by%20Shubi!5e0!3m2!1sen!2sin!4v1690512065151!5m2!1sen!2sin" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                {/* <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11222.165651600279!2d-74.6402292!3d45.3176885!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccea2b6a8f92d93%3A0xb94ada0c952f356d!2sAtlantic%20Pub%20%26%20Eatery!5e0!3m2!1sen!2sin!4v1683205867337!5m2!1sen!2sin" width="100%" height="500" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe> */}
                <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11222.165651600279!2d-74.6402292!3d45.3176885!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccea2b6a8f92d93%3A0xb94ada0c952f356d!2sAtlantic%20Pub%20%26%20Eatery!5e0!3m2!1sen!2sin!4v1683205867337!5m2!1sen!2sin"
                    width="100%"
                    height="500"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></Iframe>

            </div>

            <section className="contact-page">
                <div className="left-bg"><img src={bg25} alt="" title="" /></div>
                <div className="right-bg"><img src={bg6} alt="" title="" /></div>

                <div className="location-center" style={{zIndex:'20'}}>
                    <div className="auto-container">
                        <div className="cinfo-box">
                            <div className="row clearfix">

                                <div className="contactinfo-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Service Time</h4>
                                        <div className="text"> Wednesday to Monday <br /> 11.30 am - 8.00pm</div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Contact Info</h4>
                                        <div className="text">36 McDougald St E, Alexandria, ON K0C 1A0, Canada<br />Email : naansbyshubi@gmail.com</div>
                                        <div className="more-link"><a href="tel:+16135250665">Booking : +1 (613) 525-0665</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contactbanner