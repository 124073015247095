import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import bgone from '../../assets/images/background/bg-5.png'
import Bgtwo from '../../assets/images/background/bg-6.png'
import Appetizers from '../Allmenus/Thrdmenu/Appetizers'
import Soups from '../Allmenus/Thrdmenu/Soups'
import Tandoori from '../Allmenus/Thrdmenu/Tandoori'
import MainCourseVeg from '../Allmenus/Thrdmenu/MainCourseVeg'
import MainCourseNonVeg from '../Allmenus/Thrdmenu/MainCourseNonVeg'
import Rice from '../Allmenus/Thrdmenu/Rice'
import Briyanibig from '../Allmenus/Thrdmenu/Briyanibig'
import Combomeals from '../Allmenus/Thrdmenu/Combomeals'
import Canadianmeals from '../Allmenus/Thrdmenu/Canadianmeals'
import Pizza from '../Allmenus/Thrdmenu/Pizza'

// This is the menusection of landing page

function Menusection() {
    const [tabMenu, tabActive] = useState({ a: true })

    return (
        <>
            <section className="menu-section">

                <div className="left-bg"><img src={bgone} alt="" title="" /></div>

                <div className="right-bg"><img src={Bgtwo} alt="" title="" /></div>

                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Special selection</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Delicious Menu</h2>
                    </div>

                    <div className="tabs-box menu-tabs" style={{lineHeight: '3rem'}}>
                        <div className="buttons">
                            <ul className="tab-buttons clearfix">
                                <li className={`tab-btn ${tabMenu.a && "active-btn"}`} onClick={() => tabActive({a : true})} data-tab="#tab-1"><span>Appetizers & Soups</span></li>
                                <li className={`tab-btn ${tabMenu.b && "active-btn"}`} onClick={() => tabActive({b: true})} data-tab="#tab-2"><span>Tandoori Meals</span></li>
                                <li className={`tab-btn ${tabMenu.c && "active-btn"}`} onClick={() => tabActive({c : true})} data-tab="#tab-3"><span>Curries, Rice & Breads</span></li>
                                <li className={`tab-btn ${tabMenu.d && "active-btn"}`} onClick={() => tabActive({d : true})} data-tab="#tab-4"><span>Briyanis & Combos</span></li>
                                <li className={`tab-btn ${tabMenu.e && "active-btn"}`} onClick={() => tabActive({e : true})} data-tab="#tab-5"><span>English Menu</span></li>
                                <li className={`tab-btn ${tabMenu.f && "active-btn"}`} onClick={() => tabActive({f : true})} data-tab="#tab-6"><span>Pizza</span></li>
                            </ul>
                        </div>
                        <div className="tabs-content">

                            <div className={`tab ${tabMenu.a && "active-tab"}`} id="tab-1">
                                <Appetizers />
                                <Soups />
                            </div>

                            <div className={`tab ${tabMenu.b && "active-tab"}`} id="tab-2">
                                <Tandoori />
                            </div>

                            <div className={`tab ${tabMenu.c && "active-tab"}`} id="tab-3">
                                <MainCourseVeg />
                                <MainCourseNonVeg />
                                <Rice />
                            </div>
                            <div className={`tab ${tabMenu.d && "active-tab"}`} id="tab-4">
                                <Briyanibig />
                                <Combomeals />
                            </div>
                            <div className={`tab ${tabMenu.e && "active-tab"}`} id="tab-5">
                                <Canadianmeals />
                            </div>
                            <div className={`tab ${tabMenu.f && "active-tab"}`} id="tab-6">
                                <Pizza />
                            </div>
                        </div>
                    </div>

                    <div className="open-timing">
                        <div className="hours">Serving Wednesday to Monday <br/>from <span className="theme_color">11:30 am</span> to <span className="theme_color">8:00 pm</span></div>
                        <div className="link-box">
                            <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                <span className="btn-wrap">
                                    <span className="text-one">view all menu</span>
                                    <span className="text-two">view all menu</span>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Menusection