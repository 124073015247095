import React from 'react'
import { useEffect } from 'react';
import Lottie from "lottie-react";
import shareAnimation from "../../assets/images/icons/stars.json";
import '../Pages/Style.css';



function Review() {

    // for inserting google review from elfsight start
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://static.elfsight.com/platform/platform.js';
        script.setAttribute('data-use-service-core', '');
        script.defer = true;
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    //   for inserting google review from elfsight end
      
    return (

        <>

            <section className="story-section" style={{backgroundColor:'white', paddingTop:'50px', paddingBottom:'75px'}}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="title-box centered">
                                    <div className="subtitle" style={{color:'#4d8c57'}}><span> Testimonials </span></div>
                                    <h2 style={{textTransform:'none', color:'#4d8c57' }}>Unveiling Genuine Customer Experiences! </h2>
                                    <Lottie animationData={shareAnimation} className='shareSize' speed='2.0' />
                                    <h4 style={{color:'rgb(14,13,12)', letterSpacing: '0.2em'}}>Real Words from Real Customers!</h4>
                                </div>
                                    <div className='text' style={{color:'rgb(14,13,12)'}}>Unlike others, we empower our customers do the talking, directly on our website. Discover the real stories from our satisfied patrons, straight from their Google reviews. Experience the authenticity and join the ranks of happy customers today. Don't miss out on the true taste of excellence – call for your order now and see why our customers can't get enough!</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* For inserting google review from elfsight */}
                <div className="elfsight-app-87bd7222-16c1-4dc1-b337-5bc186d36336" style={{backgroundColor:'white',  margin:'0 12px' }}></div>

            </section>



        </>

    )
}

export default Review