import React from 'react'
import bookicon1 from '../../assets/images/resource/book.png'

// This file is being used

function Stickybutton() {
    return (
        <>

            <div className="sticky-section" id='stickybutton'>


                <div className="sticky-btn">
                    <a href="tel:+16135250665" className="theme-btn">
                        <span className="icon">
                            <img src={bookicon1} alt="" title="" style={{maxHeight:'32px'}} />
                        </span>
                        <span className="txt">Order Now</span>

                    </a>
                </div>
                
            </div>

        </>
    )
}

export default Stickybutton