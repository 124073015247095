import React from 'react'
import bg4 from '../../../assets/images/background/bg-4.png'
import daalsoup from '../../../assets/images/resource/daal-soup.webp'
import mulligatawnysoup from '../../../assets/images/resource/mulligatawny-soup.webp'


// This file is used

function Soups() {
    return (
        <>
            <section className="menu-two">

                <div className="left-bg"><img src={bg4} alt="" title="" /></div>
                <div className="auto-container">

                    <div className="title-box centered" style={{marginTop:'-100px'}}>
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Soups</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={daalsoup} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Daal Soup</h5></div><div className="price"><span>$ 6.95</span></div></div>
                                        <div className="text desc">A hearty soup made with lentils and spices. Perfect for a light and comforting meal.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={mulligatawnysoup} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Mulligatawny Soup</h5></div><div className="price"><span>$ 7.95</span></div></div>
                                        <div className="text desc">A classic Anglo-Indian soup made with chicken, lentils, and spices. A perfect balance of flavors in every spoonful.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Soups