import React from 'react'
import bg16 from '../../../assets/images/background/bg-16-1.png'
import bg18 from '../../../assets/images/background/bg-18-1.png'
import bg23 from '../../../assets/images/background/bg-23-1.png'
import pulaorice from '../../../assets/images/resource/pulao-rice.webp'
import garlicnaan from '../../../assets/images/resource/garlic-naan.webp'
import papad from '../../../assets/images/resource/papad.webp'

function Rice() {
    return (

        <>
            <section className="menu-one">
                <div className="left-bg"><img src={bg16} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Rice & Breads</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Rice</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={pulaorice} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Basmati Steamed Rice</h5></div><div className="price"><span>$ 4.95</span></div></div>
                                        <div className="text desc">Long-grain aromatic rice served steaming hot.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Pulao Rice</h5></div><div className="price"><span>$ 6.95</span></div></div>
                                        <div className="text desc">Basmati rice cooked in a flavorful broth with whole spices, vegetables and meat, resulting in a fragrant and colorful dish.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Saffron Rice</h5></div><div className="price"><span>$ 6.95</span></div></div>
                                        <div className="text desc">Basmati rice cooked with saffron and other aromatic spices, resulting in a dish with a beautiful yellow color and a distinct floral flavor.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Coconut Rice</h5></div><div className="price"><span>$ 6.95</span></div></div>
                                        <div className="text desc">Basmati rice cooked with coconut milk and aromatic spices, resulting in a creamy, fragrant, and slightly sweet dish.</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="menu-one alternate">

                <div className="right-bg-2"><img src={bg18} alt="" title="" /></div>
                <div className="auto-container">
                    
                    <div className="title-box centered">
                        <h2>Naans</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={garlicnaan} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Fresh Naan</h5></div><div className="price"><span>$ 3.95</span></div></div>
                                        <div className="text desc">A traditional Indian flatbread made with flour, water, and yeast, baked in a tandoor oven until puffy and slightly charred, perfect for dipping in curries or other sauces.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Garlic Naan</h5></div><div className="price"><span>$ 4.95</span></div></div>
                                        <div className="text desc">A flavorful twist on the classic plain naan, topped with fresh garlic and herbs, baked until fragrant and slightly crispy.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Whole Wheat Roti</h5></div><div className="price"><span>$ 3.50</span></div></div>
                                        <div className="text desc">Indian flatbread is made with a dough of whole wheat flour, water, and a touch of oil. Is cooked on a hot griddle until slightly charred.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section className="menu-one">
                <div className="left-bg"><img src={bg23} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">


                        <h2>Sides</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={papad} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Papadam</h5></div><div className="price"><span>$ 2.50</span></div></div>
                                        <div className="text desc">A crispy, thin Indian cracker made from lentil flour, typically served as an appetizer or snack.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Mint Chutney</h5></div><div className="price"><span>$ 2.50</span></div></div>
                                        <div className="text desc">A tangy and spicy condiment made with fresh mint, cilantro, green chilies, and other spices, perfect for dipping or spreading on flatbreads.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Tamarind Chutney</h5></div><div className="price"><span>$ 2.50</span></div></div>
                                        <div className="text desc">A sweet and sour condiment made with tamarind, sugar, and spices, perfect for dipping or spreading on samosas or other snacks.</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>


    )
}

export default Rice