import React, {useEffect} from 'react'
import Bredcrumb from '../Bredcrumb/Main'
import PizzaBanner from '../../assets/images/background/banner-pizza.jpg'
import Pizza from '../Allmenus/Thrdmenu/Pizza'
import { Helmet } from 'react-helmet'
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel';



// this is the file being used

function Pizzamain() {
  // facebook pixel
  useEffect(() => {
    ReactPixel.init('1727601077676996');
    ReactPixel.pageView();
  }, []);
  return (

    <>
    <Helmet>
    <meta
      name="description"
      content="Delight in our scrumptious pizzas such as Butter Chicken Pizza, Tikka Masala Pizza, Jalfrezi Pizza, and more. Made from fresh ingredients and a variety of toppings."
    />
    <meta name="title" content="Pizza - Alexandria, Canada -Naans by Shubi" />
    </Helmet>
      <Bredcrumb
        subtitle="Fusion Pizza"
        title="Main Course"
        Img={PizzaBanner}
      />
      <Pizza />
    </>

  )
}

export default Pizzamain