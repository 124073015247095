import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Bredcrumb from '../Bredcrumb/Main'
import BriyaniBanner from '../../assets/images/background/banner-briyani.jpg'
import Briyanibig from '../Allmenus/Thrdmenu/Briyanibig'
import Combomeals from '../Allmenus/Thrdmenu/Combomeals'
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel';



// this is the file being used

function Briyanicombos() {
  // facebook pixel
  useEffect(() => {
    ReactPixel.init('1727601077676996');
    ReactPixel.pageView();
  }, []);
  return (

    <>
    <Helmet>
    <meta
      name="description"
      content="Satisfy your cravings with our mouthwatering biryanis and combo meals. Enjoy fragrant rice dishes paired with delectable sides"
    />
    <meta name="title" content="Briyani and Combos - Takeaway Naans by Shubi" />
    </Helmet>
      <Bredcrumb
        subtitle="Briyanis and Combos"
        title="Main Course"
        Img={BriyaniBanner}
      />
      <Briyanibig />
      <Combomeals />

    </>

  )
}

export default Briyanicombos