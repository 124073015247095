import React from 'react'
import { Link } from 'react-router-dom';
import './Style.css'

function PrivacyPolicy() {
  return (
    <>

    <div className="container terms px-5 ">
        <div className="row text-center">
            <div className="col align-self-center">
                <div className="py-2 ">
                    <h2>Privacy Policy</h2>
                    <h4>for Naans by Shubi's</h4>
                </div>
            </div>
        </div>
        <div className="row text-start">
            <div className="col align-self-center">
                <div className="py-3">At <strong>Naans By Shubi</strong>, we recognize the importance of your personal privacy. Therefore, we have created this Privacy Policy so you can understand what information we collect and how we use it.</div>
                <div className="py-3">
                <ol>
                <li>Personal Data We Collect</li>
                    <p>Given the nature and functionality of our website, we do not collect or store any personal data of our users. Our primary goal is to facilitate users in sharing our website or Facebook page on their Facebook profiles.</p>
                <li>Use of the Data</li>
                    <p>Since we do not collect any personal data, there isn’t any possibility of using or processing it. The functionality provided by our app is solely for the purpose of sharing our content on users' Facebook profiles.</p>
                <li>Data Sharing and Transfer</li>
                    <p>No data is collected, so there is no data sharing or transfer involved. We respect the privacy of our users, and any action performed within the app is done without retrieving or storing personal user data.</p>
                <li>Third-Party Links</li>
                    <p>Our app may contain links to our official website or Facebook page. We are not responsible for the content, privacy policies, or practices of any third-party websites or services.</p>
                <li>Children's Privacy</li>
                    <p>Our service does not address anyone under the age of 13 ("Children"). We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we discover that a child under 13 has provided us with personal information, we will delete such information from our servers immediately.</p>
                <li>Changes to This Privacy Policy</li>
                    <p>We may update our Privacy Policy from time to time. We will notify users of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
                <li>Contact Us</li>
                <p>If you have any questions about this Privacy Policy, please contact us at naansbyshubi@gmail.com.</p>
                
            </ol>
                </div>
            </div>
        </div>
        <div className="container">
            <button className="align-self-center terms" type="button">
                <Link to={'/'}>Back</Link>
            </button>
        </div>
    </div>

    </>
  )
}

export default PrivacyPolicy