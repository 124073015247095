import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Bredcrumb from '../Bredcrumb/Main'
import CurriesBanner from '../../assets/images/background/banner-curries.webp'
import MainCourseVeg from '../Allmenus/Thrdmenu/MainCourseVeg'
import MainCourseNonVeg from '../Allmenus/Thrdmenu/MainCourseNonVeg'
import Rice from '../Allmenus/Thrdmenu/Rice'
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel'


// this is the file being used

function Indiancurries() {
  // facebook pixel
  useEffect(() => {
    ReactPixel.init('1727601077676996');
    ReactPixel.pageView();
  }, []);

  return (

    <>
    <Helmet>
    <meta
      name="description"
      content="Discover the rich and aromatic Indian curries at our restaurant. From butter chicken to spicy vindaloo, experience a flavorful journey."
    />
    <meta name="title" content="Indian Curries for takeaway Naans by Shubi" />
    </Helmet>
      <Bredcrumb
        subtitle="Curries and More !!!"
        title="Main Course"
        Img={CurriesBanner}
      />
      <MainCourseVeg/>
      <MainCourseNonVeg/>
      <Rice />
    </>

  )
}

export default Indiancurries