import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import logonew from '../../assets/images/logo.png'
import imgone from '../../assets/images/resource/chicken-pakora.webp'
import imgtwo from '../../assets/images/resource/chicken-malai-tikka.webp'
import Imgthree from '../../assets/images/resource/butter.webp'
import Imgfour from '../../assets/images/resource/chicken-briyani1.webp'
import Imgfive from '../../assets/images/resource/Ceasar-Naan-Wrap.webp'
import cheficon from '../../assets/images/resource/badge-1.png'
import Stickybutton from '../Menhome/Stickybutton'

// This file is main header

function Main() {

    const [active, setActive] = useState();
    const [menu, setMenu] = useState();
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled,setScrolled]=useState(false);
    const [Nav,setNav]=useState(true);
    
    const location = useLocation(); // this is for hiding certain component on certain pages
    
    

    
    const handleScroll=() => {
        
        const offset=window.scrollY;
        setlastscrool(offset);
        
        if(offset > 1000 && offset < lastscrool){
            setNav(true);
            setScrolled(true);
        }

        else if(offset > 1000 ){
          setNav(false)
        }

        else if(offset > 200){
            setNav(true);
            setScrolled(true);
        }
        
        else{
          setScrolled(false);
          setNav(true);
        }
      }
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        },);
        

    

      
    return (

        <>
        {active && 
            <div className="menu-backdrop" style={{ opacity: "1"  , visibility: "visible"}} onClick={() => setActive(false)}></div> }
            {location.pathname !== '/review_campaign/terms&condition' && <Stickybutton /> }

                {/* The side menu */}
                <section className={`hidden-bar ${active && "visible-sidebar"}`} onClick={() => setActive(false)} >
                    <div className="inner-box">
                        <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close"> </span></div>
                        <div className="logo-box"><Link to="/" title="Naans by Shubhi"><img src={logo} alt="" title="Naans by Shubhi" />
                        </Link></div>
                        

                        <div className="side-menu">
                            <ul className="navigation clearfix">
                                <li className="dropdown"><Link to="/">Home<button type="button" className="btn-expander" ></button></Link>
                                </li>
                                <li className="dropdown" ><Link to="/menu" onClick={() => setMenu(menu === true ? true : false)} onTouchMove={() => setMenu(menu === true ? true : false)} >Menus<button type="button" className="btn-expander"></button></Link>
                                    <ul style={{ display: menu ? "none" : "block", paddingLeft: '10px' }}>
                                        <li><Link to="/appetizers">Appetizers</Link></li>
                                        <li><Link to="/tandoorimeals">Tandoori Meals</Link></li>
                                        <li><Link to="/indiancurries">Indian Curries</Link></li>
                                        <li><Link to="/briyanincombos">Briyanis & Combos</Link></li>
                                        <li><Link to="/pizza">Pizza</Link></li>
                                        <li><Link to="/englishmenu">English Menu</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/contact"> Contact </Link></li>
                            </ul>
                        </div>

                        <h2>Call Us</h2>
                        <ul className="info">
                            <li>36 McDougald St E, Alexandria,<br/>Ontario, Canada, K0C 1A0</li>
                            <li>Open: 11.30 am - 8.00pm</li>
                            <li>Wednesday to Monday</li>
                            <li><Link to="mailto:naansbyshubi@gmail.com">naansbyshubi@gmail.com</Link></li>
                        </ul>
                        <div className="separator"><span></span></div>
                        <div className="booking-info">
                            <div className="bk-title">Order a Takeaway Now</div>
                            <div className="bk-no"><Link to="tel:+16135250665">+1-(613)-525-0665</Link></div>
                        </div>

                    </div>
                </section>
            {Nav && 
                <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li><i className="icon far fa-map-marker-alt"></i> 36 McDougald St E, Alexandria, Ontario, Canada, K0C 1A0</li>
                                        <li><i className="icon far fa-clock"></i> Wednesday to Monday : 11.30 am to 8.00 pm</li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                        <li><a href="tel:+16135250665"><i className="icon far fa-phone"></i>+1-(613)-525-0665</a> </li>
                                        <li><a href="mailto:naansbyshubi@gmail.com"><i className="icon far fa-envelope"></i> naansbyshubi@gmail.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {location.pathname !== '/review_campaign/terms&condition' &&
                    <div className="header-upper header-fixed" >
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-between align-items-center flex-wrap">

                                <div className="links-box clearfix">
                                    <div className="nav-toggler">
                                        <button className="hidden-bar-opener">
                                            <span className="hamburger" onClick={() => setActive(true)}>
                                                <span className="top-bun"></span>
                                                <span className="meat"></span>
                                                <span className="bottom-bun"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="logo-box">
                                    <div className="logo">
                                        <Link to="/" title="Naans by Shubhi and The Atlantic Pub - An Indo-Canadian Fusion Food Services">
                                            <img src={logonew} alt="" title="Naans by Shubhi and The Atlantic Pub - An Indo-Canadian Fusion Food Services" />
                                        </Link>
                                    </div>
                                </div>
                                
                                <div className="nav-box clearfix">
                                    <div className="nav-outer clearfix">
                                        <nav className="main-menu">
                                            <ul className="navigation clearfix">
                                                <li className="current dropdown"><Link to="/">Home</Link>
                                                </li>
                                                <li className="dropdown has-mega-menu"><Link to="/menu">Menus</Link>
                                                    <ul>
                                                        <li>
                                                            <div className="mega-menu">
                                                                <div className="menu-inner">
                                                                    <div className="auto-container">
                                                                        <div className="row clearfix">
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/appetizers"><img src={imgone} alt="" /></Link></div>
                                                                                <div className="title"><Link to="/appetizers">Appetizers</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/tandoorimeals"><img src={imgtwo} alt="" /></Link></div>
                                                                                <div className="title"><Link to="/tandoorimeals">Tandoori Meals</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/indiancurries"><img src={Imgthree} alt="" /></Link></div>
                                                                                <div className="title"><Link to="/indiancurries">Indian Curries</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/briyanincombos"><img src={Imgfour} alt="" /></Link></div>
                                                                                <div className="title"><Link to="/briyanincombos">Briyanis & Combos</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/pizza"><img src={Imgfour} alt="" /></Link></div>
                                                                                <div className="title"><Link to="/pizza">Pizza</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/englishmenu"><img src={Imgfive} alt="" /></Link></div>
                                                                                <div className="title"><Link to="/englishmenu">English Menu</Link></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/">About Us</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>
                                
                                <div className="link link-btn">
                                    <a href="tel:+16135250665" className="theme-btn btn-style-one clearfix">
                                        <span className="btn-wrap">
                                        <span className="icon text-one" style={{display:'inline-block'}}>
                                            <img src={cheficon} alt="" title="" style={{maxWidth:'56px'}} />
                                        </span>
                                            <span className="text-one" style={{display:'inline-block'}}>Order Now</span>
                                            {/* <span className="text-one txtoffer">15% off on take out orders</span> */}
                                            <span className="text-two" style={{alignSelf: 'center'}}>Call Now : (613)-525-0665</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {location.pathname === '/review_campaign/terms&condition' &&
                    <div className="header-upper header-fixed" >
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-start align-items-center flex-wrap">

                                <div className="links-box clearfix">
                                    <div className="nav-toggler">
                                        <button className="hidden-bar-opener">
                                            <span className="hamburger" onClick={() => setActive(true)}>
                                                <span className="top-bun"></span>
                                                <span className="meat"></span>
                                                <span className="bottom-bun"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-center align-items-center flex-wrap">
                            <div className="logo-box">
                                    <div className="logo">
                                        <Link to="/" title="Naans by Shubhi and The Atlantic Pub - An Indo-Canadian Fusion Food Services">
                                            <img src={logonew} alt="" title="Naans by Shubhi and The Atlantic Pub - An Indo-Canadian Fusion Food Services" />
                                        </Link>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    }

                </header>
            }
        </>

    )
}

export default Main