import React from 'react'
import bg20 from '../../../assets/images/background/bg-19.png'

import dalmakhani from '../../../assets/images/resource/dal-makhani.webp'
import daltardka from '../../../assets/images/resource/dal-tadka.webp'
import chanamasala from '../../../assets/images/resource/chana-masala.webp'
import matarpaneer from '../../../assets/images/resource/matar-paneer.webp'
import malaikofta from '../../../assets/images/resource/malai-kofta.webp'
import palakkofta from '../../../assets/images/resource/palak-kofta.webp'
import aloojeera from '../../../assets/images/resource/m-aloo-jeera.webp'
import aloogobhi from '../../../assets/images/resource/m-aloo-gobhi.webp'
import aloomatar from '../../../assets/images/resource/m-aloo-matar.webp'
import aloosaag from '../../../assets/images/resource/m-aloo-saag.webp'
import baiganbharta from '../../../assets/images/resource/m-baingan-bharta.webp'
import bhindimasala from '../../../assets/images/resource/m-bhindi-masala.webp'


// This file is used

function MainCourseVeg() {
    return (
        <>
            <section className="menu-two">

                <div className="right-bg"><img src={bg20} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Curries</span></div>
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Vegetarian</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={dalmakhani} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Dal Makhani</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">A classic Indian dish made with black lentils and kidney beans cooked in a creamy tomato-based sauce. A must-try for all lentil lovers.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={daltardka} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Dal Tadka</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Yellow lentils tempered with aromatic spices. A simple and delicious comfort food.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={chanamasala} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Chana Masala</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Chana masala is traditionally cooked with green chilies, onion, garlic, fresh cilantro, a blend of spices, chickpeas, and tomatoes.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={matarpaneer} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Matar Paneer</h5></div><div className="price"><span>$18.95</span></div></div>
                                        <div className="text desc">Indian cottage cheese and green peas cooked in a rich tomato-based sauce.</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={baiganbharta} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Eggplant Bharta</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Roasted eggplant mashed and cooked with aromatic spices. The smoky flavour will surely satisfy your palate.</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={aloogobhi} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Aloo Gobi</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Aloo gobi (potatoes & cauliflower) is a popular Indian dish in which potatoes and cauliflower are cooked with onions, tomatoes, garlic and spices.</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={aloosaag} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Aloo Saag</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Nutritious spinach along with potatoes makes the base for this traditional, healthy Indian dish, combined with potato and spices</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={bhindimasala} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Bhindi Masala</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Spiced okra sautéed with onions and tomatoes. A perfect side dish.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={aloomatar} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Aloo Matar</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Potatoes and green peas cooked in a spicy and tangy tomato-based sauce.</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={malaikofta} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Malai Kofta</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Vegetarian meatballs made with Indian cottage cheese and cooked in a creamy tomato-based sauce. A vegetarian delight.</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={palakkofta} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Palak Kofta</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Vegetarian meatballs made with spinach and Indian cheese cooked in a rich tomato-based sauce. A must-try for vegetarians.</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={aloojeera} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Jeera Aloo</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">Potatoes tempered with cumin and other spices. A simple and flavorful side dish.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MainCourseVeg