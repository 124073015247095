import React, {  useState, useEffect} from 'react'
import { Route, Routes , useLocation} from 'react-router-dom';
import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import Home from '../components/Home/Main';
import Contact from '../components/Contact/Main';
import Menu from '../components/Menu/Main';
import Menuone from '../components/Menu/Menuone';
import Tandooriitems from '../components/Menu/Tandooriitems';
import Indiancurries from '../components/Menu/Indiancurries';
import Briyanicombos from '../components/Menu/Briyanicombos';
import Englishmenuitems from '../components/Menu/Englishmenuitems';
import Aboutus from '../components/Menu/Aboutus';
import Pizzamain from '../components/Menu/Pizzamain';
import Terms from '../components/Pages/Main';
import PrivacyPolicy from '../components/Pages/PrivacyPolicy';



function Index() {

  const [homepage, sethomepage] = useState(false)
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/hometwo"){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])

  // const location = useLocation()
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
    }, [path]);

 

  return (

    <>
     
      {homepage && <Header/>}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/appetizers" element={<Menuone />} />
        <Route path="/tandoorimeals" element={<Tandooriitems/> } />
        <Route path="/indiancurries" element={<Indiancurries /> } />
        <Route path="/briyanincombos" element={<Briyanicombos /> } />
        <Route path="/englishmenu" element={<Englishmenuitems /> } />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<Aboutus /> } />
        <Route path="/pizza" element={<Pizzamain/>}/>
        <Route path='/review_campaign/terms&condition' element={<Terms/>}/>
        <Route path='/documents/privacy_policy' element={<PrivacyPolicy/>}/>
      </Routes>

      <Footer />
    </>

  )
}

export default Index