import React,{useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Tandoori from '../Allmenus/Thrdmenu/Tandoori'
import Bredcrumb from '../Bredcrumb/Main'
import TandooriBanner from '../../assets/images/background/banner_tandoori.webp'
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel'


// this is the file being used

function Tandooriitems() {
  useEffect(() => {
    ReactPixel.init('1727601077676996');
    ReactPixel.pageView();
  }, []);
  return (

    <>
    <Helmet>
    <meta
      name="description"
      content="Indulge in the authentic taste of tandoori cuisine. From succulent chicken tikka to smoky paneer, savor the essence of Indian grilling"
    />
    <meta name="title" content="Naans by Shubi - Indian Tandoori Dishes" />
    </Helmet>
      <Bredcrumb
        subtitle="Smoke it up !!!"
        title="From the Tandoor"
        Img={TandooriBanner}
      />
      <Tandoori/>

    </>

  )
}

export default Tandooriitems