import React from 'react'
import { Link } from 'react-router-dom'
import bg9 from '../../../assets/images/background/bg-9.png'
import bg1 from '../../../assets/images/background/bg-1.png'
import bg7 from '../../../assets/images/background/bg-7.png'
import bg18 from '../../../assets/images/background/bg-18-1.png'
import bg5 from '../../../assets/images/background/bg-5.png'
import bg10 from '../../../assets/images/background/bg-10.png'
import entrees from '../../../assets/images/resource/entrees.webp'
import wraps from '../../../assets/images/resource/wraps.jpg'
import englishstarter from '../../../assets/images/resource/english-starter.webp'
import chickenpoutines from '../../../assets/images/resource/chicken_poutine1.jpg'
import poutines from '../../../assets/images/resource/poutine.jpg'
import burger from '../../../assets/images/resource/burger.webp'



function Canadianmeals() {
    return (

        <>
            <section className="menu-one">
                <div className="left-bg"><img src={bg9} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage'/>
                        </div>

                        <h2>Starters</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={englishstarter} alt="" style={{height:'600px', innerWidth:'auto'}}/></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Try Our Dusted or Tossed Wings</h5></div><div className="price"><span>$14.95</span></div></div>
                                        <div className="text desc">Dry Rub Em. Salt N Pepper, Cayenne, Spicy Cajun, Lemon and Steak Spice. Toss Em Up, Stingy Honey Garlic, Memphis BBQ, Mild, Medium, Hot, Extra Hot (1 lb)</div>
                                        <div className="title clearfix"><div className="ttl clearfix"><div className='text desc s-info2' style={{color:'var(--color-four)', textTransform:'initial', fontWeight:'900', letterSpacing:'4px', paddingInline:'30px'}}>Get 2 lb</div></div><div className="price"><span>$23.95</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box" style={{minHeight:'10px'}}>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Fresh Cut Fries</h5></div><div className="price"><span>$ 6.95</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Fresh Cut Fries with Gravy</h5></div><div className="price"><span>$ 9.95</span></div></div>
                                        <div className="text desc">A large bowl of fresh cut fires smothered in house gravy</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Atlantic Salad House</h5></div><div className="price"><span>$11.95</span></div></div>
                                        <div className="text desc">Seasonal garden salad with our house dressing.</div>
                                        <div className="title clearfix"><div className="ttl clearfix"><div className='text desc' style={{textTransform:'initial'}}>Upgrade with grilled or crispy Chicken</div></div><div className="price" style={{fontSize:'medium '}}><span>+ $ 6.00</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Ceasar Salad</h5></div><div className="price"><span>$12.95</span></div></div>
                                        <div className="text desc">Traditional ceasar salad.</div>
                                        <div className="title clearfix"><div className="ttl clearfix"><div className='text desc' style={{textTransform:'initial'}}>Upgrade with grilled or crispy Chicken</div></div><div className="price" style={{fontSize:'medium '}}><span>+ $ 6.00</span></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="menu-one alternate">
                <div className="right-bg"><img src={bg1} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage'/>
                        </div>

                        <h2>Entrees</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={entrees} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Nachos</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Nachos chips smothered in Chedder/Monterey Jack cheese, Peppers, Jalapenos, Black olives. Side of sour cream and Salsa.</div>
                                        <div className="title clearfix"><div className="ttl clearfix"><div className='text desc' style={{textTransform:'initial'}}>Add seasoned ground beef or chicken</div></div><div className="price" style={{fontSize:'medium '}}><span>$ 5.00</span></div></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Beer Batter Fish & Chips</h5></div><div className="price"><span>$15.95</span></div></div>
                                        <div className="text desc">Haddock deep fried in our home made Beer batter. Fresh cut fries. Fresh tarter sauce & side of creamy coleslaw.</div>
                                        <div className="title clearfix"><div className="ttl clearfix"><div className='text desc s-info2' style={{color:'var(--color-four)', textTransform:'initial', fontWeight:'900', letterSpacing:'4px', paddingInline:'30px'}}>Get 2 pcs</div></div><div className="price"><span>$19.95</span></div></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Chicken Tenders</h5></div><div className="price"><span>$15.95</span></div></div>
                                        <div className="text desc">Freshly made chicken tenders with fresh cut fries.</div>
                                        <div className="title clearfix"><div className="ttl clearfix"><div className='text desc' style={{textTransform:'initial'}}>Upgrade to ceasar salad</div></div><div className="price" style={{fontSize:'medium '}}><span>+ $ 6.00</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Hamburger Steak</h5></div><div className="price"><span>$17.95</span></div></div>
                                        <div className="text desc">10oz AAA Hamburger smothered in House Gravy with sauteed Mushrooms and Onions. Fresh cut fries, house salad</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Sandwiches */}
            {/* <section className="menu-two">

                <div className="auto-container">
                    <div className="left-bg"><img src={bg16} alt="" title="" /></div>

                    <div className="title-box centered" style={{marginTop:'-100px'}}>
                    <div className="pattern-image">
                        <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                    </div>

                    <h2>Sandwiches</h2>
                    <h6 className='s-info1'>All Sandwiches include fresh cut fires</h6>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={grilledsandwich} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">Classic Grilled Cheese</Link></h5></div><div className="price"><span>$ 9.95</span></div></div>
                                        <div className="text desc"><Link href="#">Classic Grilled Sandwich dripping with Cheddar Cheese and Buttery Goodness</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={clubsandwich} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">Club Sandwich</Link></h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc"><Link href="#">Stacked with Chicken, Bacon, Lettuce, tomato on a Grilled Fresh Bread, Fresh cut fries, Coleslaw</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* Wraps */}
            <section className="menu-one">
                <div className="left-bg"><img src={bg1} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage'/>
                        </div>
                        <h2>Wraps</h2>
                        <h6 className='s-info1'>All Wraps include fresh cut fires</h6>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={wraps} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix">
                                            <div className="ttl clearfix">
                                            <h5>Butter Chicken Wrap</h5>
                                            </div>
                                            <div className="price"><span>$16.95</span></div>
                                        </div>
                                        <div className="text desc">Grilled chicken smothered in butter sauce, mixed greens and cheese wrapped in our homemade naan</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box" style={{minHeight:'10px'}}>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Tandoori Chicken Wrap</h5></div><div className="price"><span>$ 16.95</span></div></div>
                                        <div className="text desc">Spicy Indian Tandoori Chicken, Salad, smothered in Indian Sauce wrapped in our homemade naan</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Chicken Caesar Wrap</h5></div><div className="price"><span>$ 16.95</span></div></div>
                                        <div className="text desc">Caesar Salad with grilled or crispy chicken wrapped and grilled to perfection. Add Buffalo sauce to spice it up</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Seekh Kabab Wrap</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Choice of chicken, beef or prok kabab marinated in savoury Indian spices, served with salad and Indian sauce dressing, wrapped in our homemade naan</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Burgers */}
            <section className="menu-one alternate" style={{paddingTop:'0'}}>
                <div className="right-bg-2"><img src={bg7} alt="" title="" /></div>
                <div className="left-bg"><img src={bg18} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <h2>Burgers</h2>
                        <h6 className='s-info1'>All Burgers include fresh cut fires</h6>
                    </div>
                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={burger} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Highland Burger</h5></div><div className="price"><span>$14.95</span></div></div>
                                        <div className="text desc">Choose your topping served with fresh cut fries</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Bulliet Bourbon BBQ Cheese Burger</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">AAA Beef Burger, Bacon, Onion Rings, Coleslaw, Dill Pickle smothered in homemade Bulliet Bourbon BBQ sauce</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Grilled Chicken Burger</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Topped with Lettuce, Tomato and Mayo. Comes with fresh cut fries</div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Crispy Chicken Burger</h5></div><div className="price"><span>$16.95</span></div></div>
                                        <div className="text desc">Topped with Lettuce, Tomato and Mayo. Comes with fresh cut fries</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            {/* Poutines */}
            <section className="menu-two">

                <div className="auto-container">
                    <div className="right-bg"><img src={bg5} alt="" title="" /></div>
                    <div className="left-bg"><img src={bg10} alt="" title="" /></div>

                    <div className="title-box centered" style={{marginTop:'-100px'}}>
                    <div className="pattern-image">
                        <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                    </div>

                    <h2>Poutines</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={poutines} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">Classic Poutine</Link></h5></div><div className="price"><span>$12.95</span></div></div>
                                        <div className="text desc"><Link href="#">Fresh cut fries, home made brown gravy and St Albert curds.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                            <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={chickenpoutines} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">Butter Chicken Poutine</Link></h5></div><div className="price"><span>$18.95</span></div></div>
                                        <div className="text desc"><Link href="#">Fresh cut fries, butter chicken gravy, marinated chicken with St Albert curds.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>


    )
}

export default Canadianmeals