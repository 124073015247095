import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'
// import Startermenu from '../Allmenus/FirstMenu/Startermenu';
// import Offer from '../Allmenus/FirstMenu/Offers'
// import Reservation from '../Allmenus/FirstMenu/Reservation';
// import Bredcom from '../Bredcrumb/Main'
import Img from '../../assets/images/background/menu-banner1.webp'
import Bredcrumb from '../Bredcrumb/Main'
import Appetizers from '../Allmenus/Thrdmenu/Appetizers'
import Soups from '../Allmenus/Thrdmenu/Soups'
// eslint-disable-next-line
import ReactPixel from 'react-facebook-pixel'


function Menuone() {
  useEffect(() => {
    ReactPixel.init('1727601077676996');
    ReactPixel.pageView();
  }, []);
  return (

    <>
    <Helmet>
    <meta
      name="description"
      content="Explore our delectable array of Indian appetizers, from crispy samosas to flavorful pakoras. Start your meal with a burst of flavors."
    />
    <meta name="title" content="Naans by Shubi - Appetizers" />
    </Helmet>

      <Bredcrumb
        subtitle="Bon appétit !!!"
        title="DELICIOUS & AMAZING"
        Img={Img}
      />
      {/* <Appetizersbig /> */}
      {/* <Specialmenu /> */}
      <Appetizers />
      <Soups/>
    </>

  )
}

export default Menuone