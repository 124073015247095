import React from 'react'
import bg6 from '../../../assets/images/background/bg-6.png'
import bg25 from '../../../assets/images/background/bg-25.png'

import butter from '../../../assets/images/resource/butter.webp'
import saagwala from '../../../assets/images/resource/saagwala.webp'
import jalfrezi from '../../../assets/images/resource/jalfrezi.webp'
import korma from '../../../assets/images/resource/korma.webp'
import vindaloo from '../../../assets/images/resource/vindaloo.webp'
import mangocurry from '../../../assets/images/resource/mangocurry.webp'
import coconut from '../../../assets/images/resource/coconut.webp'



// This file is used

function MainCourseNonVeg() {
    return (
        <>
            <section className="menu-two">

                <div className="right-bg"><img src={bg6} alt="" title="" /></div>
                <div className="left-bg"><img src={bg25} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Curries</span></div>
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Non Vegetarian</h2>
                        <h4 className="s-info1">Choose your own curry with your choice of meat or vegetables</h4>
                        <h5>Chicken / Fish / Pork / Beef / Mix Vegetables or Indian Cottage Cheese</h5>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={butter} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Butter Sauce</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">A creamy and buttery tomato-based sauce. Perfect for dipping naan or as a sauce for meat dishes.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={jalfrezi} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Jalfrezi</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">A rich and creamy dish made with a blend of aromatic spices and your choice of meat or vegetables.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={korma} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Korma</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">Succulent meat or veggies simmered in cashew-yogurt sauce, infused with aromatic spices, served with fragrant basmati rice. Exquisite Indian cuisine awaits.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={mangocurry} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Mango Curry</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">A sweet and spicy curry made with fresh mangoes and your choice of meat or vegetables.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={saagwala} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Saagwala</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">Your choice of meat or vegetables cooked with spinach in a fragrant blend of spices.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={vindaloo} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Vindaloo</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">A spicy and tangy curry made with your choice of meat or vegetables and a blend of aromatic spices.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={coconut} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Coconut Curry</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">A deliciously creamy curry made with coconut milk and your choice of meat or vegetables.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        </>
    )
}

export default MainCourseNonVeg