import React from 'react'
import bg8 from '../../../assets/images/background/bg-8.png'
import bg20 from '../../../assets/images/background/bg-20.png'
import beefbriyani from '../../../assets/images/resource/beef-briyani.webp'
import vegbriyani from '../../../assets/images/resource/veg-briyani.webp'
import chickenbriyani from '../../../assets/images/resource/chicken-briyani1.webp'
import lambbriyani from '../../../assets/images/resource/lamb-briyani.webp'



function Briyanibig() {
    return (

        <>
            <section className="special-offer-two py-5">

                <div className="left-bg"><img src={bg20} alt="" title="" /></div>
                <div className="right-bg"><img src={bg8} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Briyanis</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Briyanis</h2>
                    </div>
                    <div className="row clearfix">
                        
                        <div className="offer-block-three col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><img src={vegbriyani} alt="" /></div>
                                <h4>Vegetable Biryani</h4>
                                <div className="text desc">A fragrant and flavorful rice dish made with long grain basmati rice, mixed vegetables, and aromatic spices.</div>
                                <div className="price">$17.95</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                                <div className="image"><img src={chickenbriyani} alt="" /></div>
                                <h4>Chicken Biryani</h4>
                                <div className="text desc">A classic Indian dish made with long grain basmati rice, tender chicken pieces, and aromatic spices resulting in a fragrant and flavorful dish.</div>
                                <div className="price">$20.95</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <div className="image"><img src={lambbriyani} alt="" /></div>
                                <h4>Pork Biryani</h4>
                                <div className="text desc">A rich and flavorful rice dish made with long grain basmati rice, tender Pork pieces, and aromatic spices resulting in a delicious and hearty dish.</div>
                                <div className="price">$19.95</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="image"><img src={beefbriyani} alt="" /></div>
                                <h4>Beef Biryani</h4>
                                <div className="text desc">A delicious and aromatic rice dish made with long grain basmati rice, tender beef pieces, and aromatic spices resulting in a hearty and satisfying meal.</div>
                                <div className="price">$19.95</div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </>

    )
}

export default Briyanibig