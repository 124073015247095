import React from 'react'
import bg20 from '../../../assets/images/background/bg-20.png'
import bg21 from '../../../assets/images/background/bg-18-1.png'

import tandoorplatter from '../../../assets/images/resource/tandoor_platter.jpg'
import tandoorchicken from '../../../assets/images/resource/tandoori_chicken.jpg'
import tandoorpaneer from '../../../assets/images/resource/tandoori_paneer.jpg'
import tandoorveg from '../../../assets/images/resource/tandoori_veg.jpg'
import lambkabab from '../../../assets/images/resource/lamb_kabab.jpg'
import chickenkabab from '../../../assets/images/resource/chicken_kabab.jpg'
import chickenmalai from '../../../assets/images/resource/chicken-malai-tikka.webp'
import chickentikka from '../../../assets/images/resource/chicken-tikka.webp'


// This file is used

function Tandoori() {
    return (
        <>
            <section className="menu-two">

                <div className="left-bg"><img src={bg20} alt="" title="" /></div>
                <div className="right-bg"><img src={bg21} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Tandoori Dishes</span></div>
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Tandoori Meals</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={tandoorpaneer} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Tandoori Paneer</h5></div><div className="price"><span>$17.50</span></div></div>
                                        <div className="text desc">Indian cheese marinated in a blend of spices and grilled in a traditional clay oven. Served with a side of mint chutney.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={tandoorveg} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Tandoori Veggies</h5></div><div className="price"><span>$17.50</span></div></div>
                                        <div className="text desc">Fresh vegetables marinated in aromatic spices and grilled in a traditional clay oven. Served with mint chutney.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={chickentikka} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Chicken Tikka</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">Tender pieces of chicken marinated in a blend of spices and grilled to perfection in a clay oven. Served with mint chutney.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={chickenmalai} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Chicken Malai Tikka</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">Juicy chicken pieces marinated in a creamy blend of spices and grilled to perfection. A must-try for chicken lovers.</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={tandoorchicken} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Tandoori Chicken</h5></div><div className="price"><span>$18.95</span></div></div>
                                        <div className="text desc">A classic Indian dish made with marinated chicken grilled in a traditional clay oven. Served with mint chutney.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={lambkabab} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Beef / Pork Seekh Kabab</h5></div><div className="price"><span>$18.95</span></div></div>
                                        <div className="text desc">Spicy minced meat skewers grilled to perfection. A popular Indian appetizer.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={chickenkabab} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Chicken Seekh Kabab</h5></div><div className="price"><span>$19.95</span></div></div>
                                        <div className="text desc">A succulent chicken version of the classic seekh kabab. A perfect start to any meal.</div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><img src={tandoorplatter} alt="" /></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5>Mix it up</h5></div><div className="price"><span>$25.95</span></div></div>
                                        <div className="text desc">A mix of 4 Tandoori Sizzlers of your choice (serves two)</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tandoori