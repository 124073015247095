import React from 'react';
// import Backgroundimage from '../../assets/images/background/image-4.jpg';
import Backgroundimage from '../../assets/images/background/banner-footer.jpg';
import { Link, useLocation } from 'react-router-dom'
import logonew from '../../assets/images/logo.png'
import { useState, useEffect } from 'react';



function Main() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    // const storyElement = document.getElementById('story');
    const location = useLocation();

    useEffect(() => {
      setCurrentYear(new Date().getFullYear());
    }, []);
    return (
        <>

            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}></div>
                {location.pathname !== '/review_campaign/terms&condition' &&
                    <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="Naans by Shubhi and The Atlantic Pub - An Indo-Canadian Fusion Food Services"><img src={logonew} alt="" title="Naans by Shubhi and The Atlantic Pub - An Indo-Canadian Fusion Food Services" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li>36 McDougald St E, Alexandria, Ontario, Canada, K0C 1A0</li>
                                                <li><Link to="mailto:naansbyshubi@gmail.com">naansbyshubi@gmail.com</Link></li>
                                                <li><Link to="tel:+16135250665">Takeaway Order : +1-(613)-525-0665</Link></li>
                                                <li>Open : 11:30 am - 08:00 pm</li>
                                                <li>Wednesday to Monday</li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span className='mx-3'></span><span className='mx-3'></span><span className='mx-3'></span></div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="/">Home</Link></li>
                                        <li> <Link to="/menu">Menus</Link></li>
                                        <li> <Link to="/about">About us</Link></li>
                                        <li> <Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <a href="https://www.facebook.com/profile.php?id=100091951944833" target='blank'>facebook</a></li>
                                        <li> <a href="https://www.instagram.com/naans_by_shubi/?igshid=MzRlODBiNWFlZA%3D%3D">instagram</a></li>
                                        <li> <a href="https://goo.gl/maps/YgSgXFo168Brwkm98?coh=178571&entry=tt" target='blank'>Get directions on <br/>Google map</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                }

                <div className="footer-bottom">
                    <div className="auto-container" style={{backgroundColor:'rgba(0,0,0,0.2)', padding:'5px'}}>
                        <div className="copyright">&copy; {currentYear} <Link style={{textDecoration:'none'}} to="/">Naans by Shubi</Link></div>
                        <div className="branding" style={{paddingTop:'0!important', paddingBottom:'0!important'}}>
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links" style={{fontSize:'small'}}>
                                        <li>Crafted with perfection by Jaiswal Ventures Pvt Ltd <a href="https://webmagnetx.com/" target='blank'>&#9742; www.webmagnetx.com</a></li>
                                    </ul>
                                </div>
                            </div>                      
                        <div className='copyright' style={{fontSize:'small'}}>Disclaimer: The images shown are for illustration purposes only and may not be an exact representation of the product</div>
                        <div className='copyright' style={{fontSize:'small'}}>* Total Google reviews of all our outlets</div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>

        </>

    )
}

export default Main